/// Helpers to interact with the Sui network
import { client } from "./helper";

export const IDO_PACKAGE_TESTNET =
  "0xe64aa61b4132ae241b29cda275bff617f015f25dc0d91ad831a87a56f654fc75";
export const IDO_RPC_INVESTING =
  "https://knighthood.blockbolt.io/api/v1/idoInvesting";

type Config = {
  rpc: any;
  packageId: string;
};

export function getConfig(network: string): Config {
  return {
    rpc: client,
    packageId: IDO_PACKAGE_TESTNET,
  };
}

/// Represents a `SUI::boltpay::Boltpay<T>` Sui object.
export type Boltpay = {
  id: string; // The Sui object UID
  collatType: string; // The type of collateral, i.e. the `T` in `Boltpay<T>`
  title: String;
  start_date: String;
  end_date: String;
  cap: number;
  raised: number;
  description: string;
  quorum: number;
  size: number;
  whitelistedaddress: string[];
  owner: string[];
  phase: string;
  invests: object;
  answers: object;
};

/// Fetch and parse a `SUI::boltpay::Boltpay<T>` Sui object into our custom Boltpay type
export async function getBoltpay(
  network: string,
  objId: string
): Promise<Boltpay | null> {
  // console.debug('[getBoltpay] Looking up:', objId);

  const getPhaseName = (phaseCode: number): string => {
    return ["investing", "voting", "settled", "canceled", "stalemate"][
      phaseCode
    ];
  };

  const getCollateralType = (boltpayType: string): string => {
    const match = boltpayType.match(/<(.+)>$/);
    return match ? match[1] : "ERROR_TYPE_NOT_FOUND";
  };

  const { packageId, rpc } = getConfig(network);

  // Handle leading zeros ('0x00ab::boltpay::Boltpay' is returned as '0xab::boltpay::Boltpay' by the RPC)
  const packageName = packageId.replace(/0x0+/, "0x0*"); // handle leading zeros
  const boltpayTypeRegex = new RegExp(
    `^${packageName}::boltpay::Boltpay<0x.+::.+::.+>$`
  );
  return rpc
    .getObject({
      id: objId,
      options: {
        showContent: true,
      },
    })
    .then((resp: any) => {
      if (resp.error || !resp.data) {
        // console.warn('[getBoltpay] Object does not exist. Status:', obj.status);
        return null;
      }

      const obj = resp.data.content as any;

      if (!obj.type.match(boltpayTypeRegex)) {
        // console.warn('[getBoltpay] Found wrong object type:', details.data.type);
        return null;
      } else {
        // console.debug('[getBoltpay] Found boltpay object:', obj);

        const fields = obj.fields;

        // Parse `Boltpay.invests: VecMap<address, Coin<T>>`
        let invests = fields.invests.fields.contents || [];
        let investsByPlayer = new Map(
          invests.map((obj: any) => [
            obj.fields.key,
            obj.fields.value.fields.balance,
          ])
        );

        // Parse `Boltpay.answers: VecMap<address, String>`
        let answers = fields.answers.fields.contents || [];
        let answersByPlayer = new Map(
          answers.map((obj: any) => [obj.fields.key, obj.fields.value])
        );

        // Parse `Boltpay.claims: VecMap<address, address>`
        let claims = fields.claims.fields.contents || [];
        let claimsByJudge = new Map();
        let claimsByPlayer = new Map();
        claims.forEach((obj: any) => {
          let judgeAddr = obj.fields.key;
          let playerAddr = obj.fields.value;
          claimsByJudge.set(judgeAddr, playerAddr);
          claimsByPlayer.set(
            playerAddr,
            1 + (claimsByPlayer.get(playerAddr) || 0)
          );
        });

        const boltpay: Boltpay = {
          id: fields.id.id,
          collatType: getCollateralType(obj.type),
          title: fields.title,
          start_date: fields.start_date,
          end_date: fields.end_date,
          cap: fields.cap,
          raised: fields.raised,
          description: fields.description,
          quorum: fields.quorum,
          size: fields.size,
          whitelistedaddress: fields.whitelistedaddress,
          owner: fields.owner,
          phase: getPhaseName(fields.phase),
          invests: investsByPlayer,
          answers: answersByPlayer,
        };
        return boltpay;
      }
    })
    .catch((error: any) => {
      // console.warn('[getBoltpay] RPC error:', error.message);
      return null;
    });
}

export function getErrorName(error?: string): string {
  if (!error) {
    return "unknown error";
  }

  const noBalanceTxt =
    "Unable to select a gas object with balance greater than or equal to";
  if (error.includes(noBalanceTxt)) {
    return "Your wallet doesn't have enough balance to pay for the transaction";
  }

  const match = error.match(/^MoveAbort.+, (\d+)\)$/);
  if (!match) {
    return error;
  }
  const errCode = match[1];
  const errorNames: Record<string, string> = {
    // from boltpay.move
    // create()
    "0": "E_JUDGES_CANT_BE_PLAYERS",
    "2": "E_INVALID_NUMBER_OF_PLAYERS",
    "3": "E_INVALID_NUMBER_OF_JUDGES",
    "4": "E_DUPLICATE_PLAYERS",
    "5": "E_DUPLICATE_JUDGES",
    "6": "E_INVALID_QUORUM",
    "7": "E_INVALID_BOLTPAY_SIZE",
    // invest()
    "100": "E_ONLY_PLAYERS_CAN_INVEST",
    "101": "E_ALREADY_INVESTED",
    "102": "E_INVESTS_BELOW_BOLTPAY_SIZE",
    "103": "E_NOT_IN_INVESTING_PHASE",
    "104": "E_CAP_REACHED",
    // vote()
    "200": "E_NOT_IN_VOTING_PHASE",
    "201": "E_ONLY_JUDGES_CAN_CLAM",
    "202": "E_ALREADY_CLAMD",
    "203": "E_PLAYER_NOT_FOUND",
    // cancel()
    "300": "E_BOLTPAY_HAS_INVESTS",
    "301": "E_NOT_AUTHORIZED",
  };
  return errorNames[errCode] || error;
}
