import React, { useEffect, useState } from "react";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import { isValidSuiAddress } from "@mysten/sui.js/utils";
import { getZkLoginSignature } from "@mysten/zklogin";
import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
// import axios from "axios";
import CloseIcon from "../../../../../../assets/img/close.svg";
import CheckIcon from "../../../../../../assets/img/check-new.svg";
import CopyIcon from "../../../../../../assets/img/copy.svg";
import {
  TransactionLoader,
  client,
  client_fullnode_url,
  decrypted_data,
  shorten,
  sound_box_url,
  sound_provider_id,
  toastMsg,
  resolution,
  packageMainId
} from "../../../../../lib/helper";

const TxnSend = (props: any) => {
  const {
    identityKey,
    coinValue,
    coinList,
    setShowTxnSend,
    keyPair,
    setIsTxnSuccess,
  } = props;
  const isGLogin = localStorage.getItem("wallet:gin");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [txnDigest, setTxnDigest] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [isPosTxn, setIsPosTxn] = useState(false);
  const [coinName, setCoinName] = useState("Sui");
  const [jsonContent, setJsonContent] = useState<any>({});
  const [deviceId, setDeviceId] = useState("");

  const domains = ['.unstoppable', '.dao', '.nft', '.bitcoin', '.polygon', '.x', '.pudgy', '.zil', '.blockchain', '.austin', '.unstoppable', '.go', '.888'];
  const domainFound = domains.some(domain => address.includes(domain));

  const domainsCoinList = [
    { name: 'USDC' },
    { name: 'USDT' }
  ]

  const onCopyText = () => {
    navigator.clipboard.writeText(txnDigest);
    toastMsg("Copied!");
  };

  const getWalletAddr = async (domain: string): Promise<string | null> => {
    const ticker: string = 'SUI';
    try {
      const address = await resolution.addr(domain, ticker);
      if (address) {
        return address;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const resolveNameService = async (name: string) => {
    try {
      const address = await client.resolveNameServiceAddress({ name });
      return address;
    } catch (e) {
      return null;
    }
  };

  // const txnWithSoundBox = (getAmount: any) => {
  //   const data = {
  //     device_id: deviceId,
  //     ip_key: sound_provider_id,
  //     text: `Received ${getAmount} Sui On Blockbolt`,
  //   };
  //   try {
  //     axios
  //       .post(sound_box_url, data)
  //       .then(() => {
  //         console.log("success");
  //       })
  //       .catch(() => {
  //         console.log("fail");
  //       });
  //   } catch (error: any) {
  //     console.log("error", error);
  //   }
  // };

  async function txnWithSoundBox(getAmount: any) {
    const getCoinName = isPosTxn ? coinName == "Sui" ? "USDC" : coinName : coinName
    const data = {
      device_id: deviceId,
      ip_key: sound_provider_id,
      text: `Received ${getAmount} ${getCoinName} on SUI chain on Blockbolt`
    };
    try {
      const response = await fetch(sound_box_url, {
        method: "POST",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          crossOrigin: "anonymous",
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  //   const data = {
  //     device_id: deviceId,
  //     ip_key: sound_provider_id,
  //     text: `Received ${getAmount} Sui On Blockbolt`,
  //   };
  //   try {
  //     axios
  //       .post(sound_box_url, data)
  //       .then(() => {
  //         console.log("success");
  //       })
  //       .catch(() => {
  //         console.log("fail");
  //       });
  //   } catch (error: any) {
  //     console.log("error", error);
  //   }
  // };

  // send part start
  const txnWithZK = async (getAddress: any) => {
    const savedAddress = localStorage.getItem("wallet:address");
    const state = JSON.parse(localStorage.getItem("wallet:key") as any);
    const inputs = JSON.parse(localStorage.getItem("wallet:inputs") as any);

    const tx = new TransactionBlock();
    const txnValue = Math.floor(+amount * 1_000_000_000);

    if (coinName.toLocaleLowerCase() == "sui") {
      const coins = tx.splitCoins(tx.gas, [tx.pure(txnValue)]);
      tx.transferObjects([coins], tx.pure(getAddress));
    } else {
      const getObjectkey = await getObjectId(txnValue);
      const [coin] = tx.splitCoins(tx.object(getObjectkey), [
        tx.pure(txnValue),
      ]);
      tx.transferObjects([coin], tx.pure(getAddress));
    }

    if (!state) {
      console.error("Required state not found in localStorage");
      return;
    }
    if (!inputs) {
      console.error("Required inputs not found in localStorage");
      return;
    }
    if (!savedAddress) {
      console.error("Required saved not found in localStorage");
      return;
    }
    tx.setSender(decrypted_data(savedAddress));

    const ephemeralKeyPair = Ed25519Keypair.fromSecretKey(
      Buffer.from(state.ephPrivate, "base64")
    );

    const preSign = await tx.sign({
      client: client,
      signer: ephemeralKeyPair,
    });

    const zkSignature = getZkLoginSignature({
      inputs: inputs,
      maxEpoch: state.epoch,
      userSignature: preSign.signature,
    });

    const res = await client.executeTransactionBlock({
      transactionBlock: preSign.bytes,
      signature: zkSignature,
    });
    // Here you can emit the event or handle the response.
    if (res.digest) {
      setTxnDigest(res.digest);
      setShowLoading(false);
    }
  };

  const getDomainsObjectId = async (txnValue: any, getCoinName: any) => {
    let cointype0 = getCoinName === "USDC" ? "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN" : "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN";
    const walletAddress = keyPair.getPublicKey().toSuiAddress();

    let res = await fetch(`${client_fullnode_url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [walletAddress, cointype0],
      }),
    });
    let json = await res.json();
    if (json && json.result && json.result.data) {
      if (json.result.data.length === 0) {
        toastMsg(`OOPS! You dont have ${getCoinName}`)
        setShowLoading(false);
        return;
      }
      let isNotValid = 0;
      for (var i = 0; i < json.result.data.length; i++) {
        if (json.result.data[i].balance > txnValue) {
          return json.result.data[i].coinObjectId;
        } else {
          isNotValid += 1;
        }
      }
      if (isNotValid !== 0) {
        toastMsg("OOPS! You dont have sufficient balance");
        setShowLoading(false);
        return;
      }
    }
  };

  const getObjectId = async (txnValue: any) => {
    const coin_type = coinList.find(
      (coin: any) => coinName == coin.metadata.name
    );

    let coinKey = "";
    if (coin_type) {
      coinKey = coin_type.getCoinKey;
    }
    const walletAddress = keyPair.getPublicKey().toSuiAddress();

    let res = await fetch(`${client_fullnode_url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: 1,
        method: "suix_getCoins",
        params: [walletAddress, coinKey],
      }),
    });
    let json = await res.json();
    if (json && json.result && json.result.data) {
      let isNotValid = 0;
      for (var i = 0; i < json.result.data.length; i++) {
        if (json.result.data[i].balance > txnValue) {
          return json.result.data[i].coinObjectId;
        } else {
          isNotValid += 1;
        }
      }
      if (isNotValid !== 0) {
        toastMsg("OOPS! You dont have sufficient balance");
        setShowLoading(false);
        return 0;
      }
    }
  };

  const txnWithSui = async (getAddress: any) => {
    const tx = new TransactionBlock();
    const txnValue = Math.floor(+amount * 1_000_000_000);

    if (coinName.toLocaleLowerCase() == "sui") {
      const coins = tx.splitCoins(tx.gas, [tx.pure(txnValue)]);
      tx.transferObjects([coins], tx.pure(getAddress));
    } else {
      const getObjectkey = await getObjectId(txnValue);

      if (getObjectkey === undefined || getObjectkey === null) {
        // toastMsg("OOPS You dont have that coin");
        setShowLoading(false);
        return;
      }

      if (getObjectkey) {
        const [coin] = tx.splitCoins(tx.object(getObjectkey), [
          tx.pure(txnValue),
        ]);
        tx.transferObjects([coin], tx.pure(getAddress));
      }
    }

    try {
      const result = client.signAndExecuteTransactionBlock({
        signer: keyPair,
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });
      result.then((response: any) => {
        if (response && response.digest) {
          if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "success"
          ) {
            const txn_digest = response && response.digest;
            if (
              txn_digest !== "" &&
              txn_digest !== null &&
              txn_digest !== undefined
            ) {
              if (isPosTxn) {
                txnWithSoundBox(amount);
                setTxnDigest(txn_digest);
                setShowLoading(false);
              } else {
                setTxnDigest(txn_digest);
                setShowLoading(false);
              }
            }
          } else if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "failure"
          ) {
            setShowLoading(false);
            toastMsg(`Transaction Failed: ${response.effects.status.error}`);
          }
        } else {
          setShowLoading(false);
          toastMsg("Transaction Failed");
        }
      });
    } catch (error) { }

  };

  const txnWithDomains = async (getAddress: any, geCoinName: any) => {
    const tx = new TransactionBlock();
    const txnValue = Math.floor(+amount * 1_000_000);
    const getObjectKey = await getDomainsObjectId(txnValue, geCoinName)

    if (getObjectKey === undefined || getObjectKey === null) {
      // toastMsg("OOPS You dont have that coin");
      setShowLoading(false);
      return;
    }

    let cointype0 = geCoinName === "USDC" ? "0x5d4b302506645c37ff133b98c4b50a5ae14841659738d6d733d59d0d217a93bf::coin::COIN" : "0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN";
    const randomKey = Math.floor(1000000 + Math.random() * 9000000);
    const [coins] = tx.splitCoins(tx.object(getObjectKey), [tx.pure(txnValue)]);

    tx.moveCall({
      target: `${packageMainId}::boltpay::create_tx`,
      typeArguments: [cointype0],
      arguments: [
        coins,
        tx.pure(randomKey),
        tx.pure(jsonContent?.merchant_name),
        tx.pure(getAddress),
        tx.pure(txnValue),
      ],
    });

    try {
      const result = client.signAndExecuteTransactionBlock({
        signer: keyPair,
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });
      result.then((response: any) => {
        if (response && response.digest) {
          if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "success"
          ) {
            const txn_digest = response && response.digest;
            if (
              txn_digest !== "" &&
              txn_digest !== null &&
              txn_digest !== undefined
            ) {
              if (isPosTxn) {
                txnWithSoundBox(amount);
                setTxnDigest(txn_digest);
                setShowLoading(false);
              } else {
                setTxnDigest(txn_digest);
                setShowLoading(false);
              }
            }
          } else if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "failure"
          ) {
            setShowLoading(false);
            toastMsg(`Transaction Failed: ${response.effects.status.error}`);
          }
        } else {
          setShowLoading(false);
          toastMsg("Transaction Failed");
        }
      });
    } catch (error) { }
  };

  const onPaymentSend = async () => {
    setShowLoading(true);
    if (address == "" && amount == "") {
      toastMsg("something went wrong");
      setShowLoading(false);
      return;
    }

    if (amount == "") {
      toastMsg("amount must needed");
      setShowLoading(false);
      return;
    }

    if (domainFound) {
      const getAddress = await getWalletAddr(address);
      coinName == "Sui" && setCoinName("USDC")
      const getCoinName = coinName == "Sui" ? "USDC" : "USDT"
      if (getAddress) {
        txnWithDomains(getAddress, getCoinName);
        return;
      } else {
        toastMsg('Invalid Unstoppable Domain');
        setShowLoading(false);
        return;
      }
    } else if (address.includes(".sui")) {
      let getAddress = await resolveNameService(address);
      if (!getAddress) {
        toastMsg("Is not a valid name");
        setShowLoading(false);
        return;
      } else {
        if (isGLogin == "yes") {
          txnWithZK(getAddress);
          return;
        } else {
          txnWithSui(getAddress);
          return;
        }
      }
    } else {
      const isValidAddress = isValidSuiAddress(address);
      if (!isValidAddress) {
        toastMsg("Is not a valid address");
        setShowLoading(false);
        return;
      }
    }

    // var regex = /^[0-9]+$/;
    // if (!amount.match(regex)) {
    //   toastMsg("must have input number");
    //   setShowLoading(false);
    //   return;
    // }

    if (!(coinValue > amount)) {
      toastMsg("you dont have enough balance");
      setShowLoading(false);
      return;
    }

    if (isGLogin == "yes") {
      txnWithZK(address);
    } else {
      txnWithSui(address);
    }
  };

  useEffect(() => {
    if (identityKey !== false) {
      const isSuiAddress = isValidSuiAddress(identityKey);
      if (isSuiAddress) {
        setAddress(identityKey);
      } else {
        const parsedData = JSON.parse(identityKey);
        setJsonContent(parsedData);
        if (parsedData.merchant_address) {
          setIsPosTxn(true);
          setDeviceId(parsedData.machine_id);
          setAddress(parsedData.merchant_address);
        }
      }
    }
  }, []);

  return (
    <>
      {showLoading && <div className="black-bg"></div>}
      <div className="txn-send-wrapper">
        <div className="txn-heading-content inner_header">
          {txnDigest != "" ? (
            <>
              <img
                src={CloseIcon}
                alt="Back"
                onClick={() => {
                  setIsTxnSuccess(true), setShowTxnSend(false);
                }}
              />
              <h5>BlockBolt</h5>
            </>
          ) : (
            <>
              <img
                src={CloseIcon}
                alt="close"
                onClick={() => setShowTxnSend(false)}
              />
              <h5>Send Coin</h5>
            </>
          )}
        </div>
        <div>
          {txnDigest !== "" ? (
            <div className="txn-send-success">
              <img src={CheckIcon} alt="BlockBolt" className="check_icon" />
              <h4>Transaction completed successfully</h4>
              <div className="send_success_details">
                <p>
                  Amount{" "}
                  <span className="send_success_details_inner">
                    {amount} SUI
                  </span>
                </p>
                <p>
                  To{" "}
                  <span className="send_success_details_inner">
                    {shorten(address)}
                  </span>
                </p>
                <p className="transaction_ID">
                  TXID{" "}
                  <span className="send_success_details_inner">
                    {shorten(txnDigest)}
                    <img
                      src={CopyIcon}
                      onClick={() => onCopyText()}
                      className="copy_icon"
                    />
                  </span>
                </p>
              </div>
              <div className="explorer wallet-common-button">
                <button
                  className="wallet-common-button"
                  onClick={() => {
                    setIsTxnSuccess(true), setShowTxnSend(false);
                  }}
                >
                  Back to main
                </button>
              </div>
            </div>
          ) : (
            <div className="txn-send-form">
              <p>Amount</p>
              <input
                type="number"
                className="form-control"
                placeholder="0.01"
                onChange={(e) => setAmount(e.target.value)}
              />
              <p>Address</p>
              {identityKey == false ? (
                <input
                  type="text"
                  className="form-control"
                  placeholder="0x3121399d6ca64e08428d7e0010ac23c269ec379ac6f7fa411cb1c994c11b6f63"
                  onChange={(e) => setAddress(e.target.value)}
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  readOnly
                  disabled
                  value={address}
                />
              )}
              {domainFound ? (
                <div className="selectCoin">
                  <p>Select Coin</p>
                  <div className="select-field-wrapper">
                    <select
                      className="input selectCoinInner"
                      id="coin"
                      name="coin"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                      defaultValue="USDC"
                    >
                      {domainsCoinList &&
                        domainsCoinList.map((coin: any) => {
                          return (
                            <option
                              value={coin.name}
                              key={coin.name}
                            >{`${coin.name}`}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="selectCoin">
                  <p>Select Coin</p>
                  <div className="select-field-wrapper">
                    <select
                      className="input selectCoinInner"
                      id="coin"
                      name="coin"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                      defaultValue="SUI"
                    >
                      {coinList &&
                        coinList.map((coin: any) => {
                          return (
                            <option
                              value={coin.metadata.name}
                              key={coin.metadata.name}
                            >{`${coin.metadata.name}`}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              )}
              {jsonContent.merchant_name && (
                <>
                  <p>Merchant Name</p>
                  <input
                    type="string"
                    className="form-control"
                    value={jsonContent.merchant_name}
                    readOnly
                    disabled
                  />
                </>
              )}
              <div className="gas_fees">
                <p>Estimated Gas Fees</p>
                <p>--</p>
              </div>

              <button
                className="wallet-common-button"
                onClick={() => onPaymentSend()}
              >
                Send
              </button>
            </div>
          )}
        </div>
        {showLoading && <TransactionLoader />}
      </div>
    </>
  );
};

export default TxnSend;
