import React, { useContext, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  NetworkContext,
  client_network,
  decrypted_data,
  toastMsg,
} from "../../../../lib/helper";
// import RoundSelectedIcon from "../../../../../assets/img/round-selected.svg";
import CloseIcon from "../../../../../assets/img/close.svg";
import CopyIcon from "../../../../../assets/img/copy.svg";
import eyeClose from "../../../../../assets/img/eye_close.svg";
import eyeOpen from "../../../../../assets/img/eye_open.svg";
import SignOutIcon from "../../../../../assets/img/sign-out.svg";

export function NavigationNetworkProfile(props: any) {
  const { setShowNavigation } = props;
  const {
    // walletNetwork,
    // setWalletNetwork,
    // setWalletNetworkUrl,
    setShowSignOut,
  } = useContext(NetworkContext);
  const [showEyeIcon, setShowEyeIcon] = useState(false);
  const getMnemonic = localStorage.getItem("wallet:mnemonic");
  const getEmail = localStorage.getItem("wallet:email");
  const isGLogin = localStorage.getItem("wallet:gin");

  const onCopied = (data: any) => {
    navigator.clipboard.writeText(data);
    toastMsg("Copied!");
  };

  // const manageMainnetNetwork = () => {
  //   setWalletNetwork("mainnet");
  //   setWalletNetworkUrl("https://wallet-rpc.mainnet.sui.io/");
  //   localStorage.setItem("network:name", "mainnet");
  //   localStorage.setItem("network:url", "https://wallet-rpc.mainnet.sui.io/");
  //   toastMsg("Congrats!! Switched to mainnet");
  //   setShowNavigation(false);
  // };

  return (
    <>
      <div className="navigation-black-bg"></div>
      <div className="navigation-wrapper">
        <ToastContainer />
        <div className="nav-header">
          <h5>Settings</h5>
          <img
            src={CloseIcon}
            alt="close"
            onClick={() => setShowNavigation(false)}
          />
        </div>
        <div className="content-wrapper">
          {/* {walletNetwork === "mainnet" ? (
            <div className="active_round">
              <p onClick={() => manageMainnetNetwork()}>Sui Mainnet</p>
              <img src={RoundSelectedIcon} />
            </div>
          ) : (
            <p onClick={() => manageMainnetNetwork()}>Sui Mainnet</p>
          )} */}
          <div className="active_round">
            <p>Network</p>
            <p className="active_network">{client_network}</p>
          </div>
          {isGLogin != "yes" ? (
            <>
              <div className="mnemonic">
                <p>Export Mnemonic</p>
                {showEyeIcon ? (
                  <img
                    src={eyeOpen}
                    onClick={() => setShowEyeIcon(false)}
                    className="eye_icon"
                  />
                ) : (
                  <img
                    src={eyeClose}
                    onClick={() => setShowEyeIcon(true)}
                    className="eye_icon"
                  />
                )}
              </div>
              <div className="exportWrapper">
                {showEyeIcon && (
                  <>
                    <p>{decrypted_data(getMnemonic)}</p>
                    <div
                      id="exportWrapperCopy"
                      onClick={() => onCopied(decrypted_data(getMnemonic))}
                    >
                      <p>copy</p>
                      <img src={CopyIcon} className="copy_icon" />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                <p>ZKLogin</p>
                {showEyeIcon ? (
                  <img
                    src={eyeOpen}
                    onClick={() => setShowEyeIcon(false)}
                    className="eye_icon"
                  />
                ) : (
                  <img
                    src={eyeClose}
                    onClick={() => setShowEyeIcon(true)}
                    className="eye_icon"
                  />
                )}
              </div>
              <div className="exportWrapper">
                {showEyeIcon && (
                  <>
                    <p>{decrypted_data(getEmail)}</p>
                    <div
                      id="exportWrapperCopy"
                      onClick={() => onCopied(decrypted_data(getEmail))}
                    >
                      <p>copy</p>
                      <img src={CopyIcon} className="copy_icon" />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          <a href="mailto:support@blockbolt.io" target="_blank">
            <p>Contact Us</p>
          </a>
          <div
            className="logoutButton"
            onClick={() => {
              setShowSignOut(true), setShowNavigation(false);
            }}
          >
            <p>Logout</p>
            <img src={SignOutIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
