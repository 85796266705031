import { useEffect, useState } from "react";
import { wordlist } from "@scure/bip39/wordlists/english";
import * as bip39 from "@scure/bip39";
import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
import { validateMnemonic as bip39ValidateMnemonic } from "@scure/bip39";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLogo from "../../../../../../assets/img/logo.svg";
import CopyIcon from "../../../../../../assets/img/copy.svg";
import {
  client_network,
  client_network_url,
  encrypted_data,
  toastMsg,
} from "../../../../../lib/helper";
import { DashboardComponent } from "../../Dashboard/DashboardComponent";

function CreateWallet(props: any) {
  const { setShowCreateWallet } = props;
  const [mnemonic, setMnemonic] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  const [haveSaved, setHaveSaved] = useState(false);

  const onCopyText = () => {
    navigator.clipboard.writeText(mnemonic);
    toastMsg("Copied!");
  };

  const createWallet = () => {
    if (mnemonic !== "") {
      const TEST_MNEMONICS = mnemonic;
      const keypair = Ed25519Keypair.deriveKeypair(
        TEST_MNEMONICS,
        "m/44'/784'/0'/0'/0'"
      );
      if (keypair) {
        if (
          bip39ValidateMnemonic(TEST_MNEMONICS, wordlist) &&
          (TEST_MNEMONICS.split(" ") || []).length === 12
        ) {
          localStorage.setItem("wallet:mnemonic", encrypted_data(mnemonic));
          localStorage.setItem("wallet:gin", "no");
          localStorage.setItem("network:name", encrypted_data(client_network));
          localStorage.setItem(
            "network:url",
            encrypted_data(client_network_url)
          );
          setShowWallet(true);
          toastMsg("Wallet created successfully");
        } else {
          toastMsg("There is no wallet related to those mnemonic");
        }
      }
    } else {
      toastMsg("Something went wrong");
    }
  };

  useEffect(() => {
    const getMnemonic = bip39.generateMnemonic(wordlist);
    setMnemonic(getMnemonic);
  }, []);

  return (
    <>
      <ToastContainer />
      {showWallet ? (
        <DashboardComponent />
      ) : (
        <div className="create-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <p className="create-content">
            Your recovery phrase makes it easy to back up and restore your
            account.
          </p>
          <div className="create-ib-wrapper">
            <textarea
              rows={3}
              className="form-control "
              value={mnemonic}
              readOnly
            />
            <img
              src={CopyIcon}
              className="copy_icon"
              onClick={() => onCopyText()}
            />
          </div>
          <div className="recoveryCheckbox">
            <input type="checkbox" onClick={() => setHaveSaved(!haveSaved)} />
            <p>I saved my recovery phrase</p>
          </div>
          <div className="twoBottomBtns">
            <button
              className={
                haveSaved
                  ? "wallet-common-button"
                  : "wallet-common-button disable"
              }
              onClick={() => haveSaved && createWallet()}
            >
              Create Wallet
            </button>
            <button
              className="wallet-common-button borderBtn"
              onClick={() => setShowCreateWallet(false)}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default CreateWallet;
