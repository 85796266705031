import { useEffect, useState } from "react";
import {
  generateNonce,
  // ZkSignatureInputs,
  generateRandomness,
} from "@mysten/zklogin";
import {
  Ed25519Keypair,
  Ed25519PublicKey,
} from "@mysten/sui.js/keypairs/ed25519";
import AppLogo from "../../../../../../assets/img/logo.svg";
import suiLogo from "../../../../../../assets/img/sui-logo(white).svg";
import google_icon from "../../../../../../assets/img/google_icon.svg";
import {
  Loader,
  client,
  client_network,
  client_network_url,
  encrypted_data,
  toastMsg,
} from "../../../../../lib/helper";
import ImportWallet from "../ImportWallet";
import CreateWallet from "../CreateWallet";

function JoinWallet() {
  const [showImportWallet, setShowImportWallet] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const withZKLogin = async () => {
    try {
      const { epoch } = await client.getLatestSuiSystemState();
      const maxEpoch = Number(epoch) + 2;
      const ephemeralKeyPair = new Ed25519Keypair();
      const randomness = generateRandomness();

      const key = {
        epoch: maxEpoch,
        randomness: randomness.toString(),
        ephPublic: ephemeralKeyPair.getPublicKey().toBase64(),
        ephPrivate: ephemeralKeyPair.export().privateKey,
      };

      const nonce = generateNonce(
        ephemeralKeyPair.getPublicKey() as any,
        maxEpoch,
        randomness
      );

      const REDIRECT_URI = "https://sui.wallet.blockbolt.io/"; // Adjust as needed
      // const REDIRECT_URI = "http://localhost:1234/"; // Adjust as needed
      const params = new URLSearchParams({
        state: new URLSearchParams({ redirect_uri: REDIRECT_URI }).toString(),
        client_id:
          "692050671434-s60clqaqgmdh0fcaqj6dresivufppfgl.apps.googleusercontent.com",
        // "25769832374-famecqrhe2gkebt5fvqms2263046lj96.apps.googleusercontent.com",
        redirect_uri: "https://zklogin-dev-redirect.vercel.app/api/auth",
        response_type: "id_token",
        scope: "openid email",
        nonce: nonce,
      });
      const loginURL = `https://accounts.google.com/o/oauth2/v2/auth?${params}`;
      localStorage.setItem("wallet:key", JSON.stringify(key));
      window.location.href = loginURL; // Redirect to the login page
    } catch (error) {
      console.error("Error during the authentication process:", error);
    }
  };

  return (
    <>
      {showImportWallet ? (
        <ImportWallet setShowImportWallet={setShowImportWallet} />
      ) : showCreateWallet ? (
        <CreateWallet setShowCreateWallet={setShowCreateWallet} />
      ) : (
        <div className="wallet-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <div className="wallet-content">
            <p>
              Contactless and Decentralized: BlockBolt’s Innovative Payment
              Approach
            </p>
          </div>
          <div className="threeBottomBtns">
            <button
              className="wallet-common-button"
              onClick={() => setShowCreateWallet(true)}
            >
              Create Wallet
            </button>
            <button
              className="wallet-common-button"
              onClick={() => setShowImportWallet(true)}
            >
              Import Wallet
            </button>

            <div className="zklogin">
              <p id="or">OR</p>
              <p>Create or Login your wallet (zkLogin)</p>
              <button
                className="zklogin_btn"
                onClick={() => {
                  setShowLoading(true);
                  withZKLogin();
                }}
              >
                {showLoading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    <img src={google_icon} />
                    <p>Sign in with Google</p>
                  </>
                )}
              </button>
            </div>
          </div>
          <div className="powered_by">
            <p>Powered by</p>
            <img src={suiLogo} className="sui-logo" alt="BlockBolt" />
          </div>
        </div>
      )}
    </>
  );
}

export default JoinWallet;
