import React, { useState, useContext, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { QrReader } from "react-qr-reader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValidSuiAddress } from "@mysten/sui.js/dist/cjs/utils";
import CloseIcon from "../../../../../assets/img/close.svg";
import {
  Context,
  TxnContext,
  client_network,
  toastMsg,
} from "../../../../lib/helper";
import TxnSend from "../Transaction/TxnSend";
import MintNFT from "../mintNFT";
import { TransactionFormComponent } from "../Transaction/TransactionFormComponent";

export function QrScanner(props: any) {
  const { keyPair, coinList } = props;
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showTxnSend, setShowTxnSend] = useState(false);
  const [showReader, setShowReader] = useState(true);
  const [showMint, setShowMint] = useState(false);
  const { setShowScanner, walletAddress, setIsTxnSuccess, coinValue } =
    useContext(Context);

  const onCommVerify = (getData: any, values: any) => {
    if (values[4].toLowerCase() == client_network) {
      setScanResultWebCam(getData);
      setShowReader(false);
    } else {
      toastMsg("OOPS! You should have to switch network");
      setShowScanner(false);
    }
  };

  const onPaybylinksVerify = (getData: any, values: any) => {
    if (values[4].toLowerCase() == client_network) {
      if (values[1] == walletAddress) {
        setScanResultWebCam(getData);
        setShowReader(false);
      } else {
        toastMsg("OOPS! You're not eligible for payment");
        setShowScanner(false);
      }
    } else {
      toastMsg("OOPS! You should have to switch network");
      setShowScanner(false);
    }
  };

  const onIdoVerify = (getData: any, values: any) => {
    if (values[4].toLowerCase() == client_network) {
      if (values[2] == walletAddress) {
        setScanResultWebCam(getData);
        setShowReader(false);
      } else {
        toastMsg("OOPS! You're not eligible for investing into this ido");
        setShowScanner(false);
      }
    } else {
      toastMsg("OOPS! You should have to switch network");
      setShowScanner(false);
    }
  };

  const mintNFTVerify = (getData: any) => {
    // if (values[2].toLocaleLowerCase() == client_network) {

    setShowMint(true);
    setShowReader(false);
    setScanResultWebCam(getData);
    // } else {
    // toastMsg("OOPS! You should have to switch network");
    // setShowScanner(false);
    // }
  };

  const parseToJson = (scanData: any) => {
    const parsedData = JSON.parse(scanData);

    const values = Object.values(parsedData);

    if (
      scanData.includes("merchant_address") &&
      scanData.includes("merchant_name") &&
      scanData.includes("order_id") &&
      scanData.includes("amount") &&
      scanData.includes("network") &&
      scanData.includes("blockchain") &&
      scanData.includes("coin_name") &&
      scanData.includes("coin_type") &&
      values.length == 8
    ) {
      onCommVerify(scanData, values);
    } else if (
      scanData.includes("merchant_address") &&
      scanData.includes("customer_address") &&
      scanData.includes("order_id") &&
      scanData.includes("amount") &&
      scanData.includes("network") &&
      scanData.includes("merchant_name") &&
      scanData.includes("blockchain") &&
      scanData.includes("coin_name") &&
      scanData.includes("coin_type") &&
      values.length == 9
    ) {
      onPaybylinksVerify(scanData, values);
    } else if (
      scanData.includes("merchant_address") &&
      scanData.includes("woocommerce_name") &&
      scanData.includes("order_id") &&
      scanData.includes("amount") &&
      scanData.includes("network") &&
      scanData.includes("blockchain") &&
      scanData.includes("coin_name") &&
      scanData.includes("coin_type") &&
      values.length == 8
    ) {
      onCommVerify(scanData, values);
    } else if (
      scanData.includes("merchant_address") &&
      scanData.includes("merchant_name") &&
      scanData.includes("machine_id") &&
      scanData.includes("provider_id")
    ) {
      setScanResultWebCam(scanData);
      setShowTxnSend(true);
      setShowReader(false);
    } else if (scanData.includes("object_id")) {
      onIdoVerify(scanData, values);
    } else if (
      scanData.includes("packageId") &&
      scanData.includes("mintfee") &&
      scanData.includes("mintstate") &&
      scanData.includes("name") &&
      scanData.includes("description") &&
      scanData.includes("network") &&
      scanData.includes("orderId") &&
      scanData.includes("type")
    ) {
      mintNFTVerify(scanData);
    } else {
      toastMsg("OOPS!! That doesn't look like valid QR Code");
    }
  };

  const handleScan = (scanData: any) => {
    const isSuiAddress = isValidSuiAddress(scanData);
    if (isSuiAddress) {
      setScanResultWebCam(scanData);
      setShowTxnSend(true);
      setShowReader(false);
    } else {
      parseToJson(scanData);
    }
  };

  return (
    <>
      <ToastContainer />
      {showReader ? (
        <>
          <Grid
            item
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="qr-scan-grid"
          >
            <QrReader
              scanDelay={100}
              constraints={{
                facingMode: "environment",
              }}
              onResult={(result, error) => {
                if (!!result) {
                  handleScan(result?.text as any);
                }
              }}
            />
          </Grid>
          <img
            src={CloseIcon}
            alt="close"
            id="close_QR"
            onClick={() => setShowScanner(false)}
          />
        </>
      ) : showMint ? (
        <>
          <TxnContext.Provider
            value={{ scanResultWebCam, keyPair, setIsTxnSuccess }}
          >
            <MintNFT identityKey={scanResultWebCam} />
          </TxnContext.Provider>
        </>
      ) : showTxnSend ? (
        <TxnSend
          identityKey={scanResultWebCam}
          coinValue={coinValue}
          coinList={coinList}
          setShowTxnSend={setShowScanner}
          keyPair={keyPair}
          setIsTxnSuccess={setIsTxnSuccess}
        />
      ) : (
        <TxnContext.Provider
          value={{ scanResultWebCam, keyPair, setIsTxnSuccess }}
        >
          <TransactionFormComponent />
        </TxnContext.Provider>
      )}
    </>
  );
}
