import { useEffect, useState } from "react";
import { wordlist } from "@scure/bip39/wordlists/english";
import { validateMnemonic as bip39ValidateMnemonic } from "@scure/bip39";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppLogo from "../../../../../../assets/img/logo.svg";
import {
  client_network,
  client_network_url,
  encrypted_data,
  toastMsg,
} from "../../../../../lib/helper";
import { DashboardComponent } from "../../Dashboard/DashboardComponent";

function ImportWallet(props: any) {
  const { setShowImportWallet } = props;
  const [importWords, setImportWords] = useState("");
  const [showWallet, setShowWallet] = useState(false);

  const importWallet = () => {
    if (importWords !== "") {
      const TEST_MNEMONICS = importWords.toLowerCase();
      if (
        bip39ValidateMnemonic(TEST_MNEMONICS, wordlist) &&
        (TEST_MNEMONICS.split(" ") || []).length === 12
      ) {
        localStorage.setItem("wallet:mnemonic", encrypted_data(importWords));
        localStorage.setItem("wallet:gin", "no");
        localStorage.setItem("network:name", encrypted_data(client_network));
        localStorage.setItem("network:url", encrypted_data(client_network_url));
        setShowWallet(true);
        toastMsg("Wallet imported successfully");
      } else {
        toastMsg("There is no wallet related to those mnemonic");
      }
    } else {
      toastMsg("Something went wrong");
    }
  };

  return (
    <>
      <ToastContainer />
      {showWallet ? (
        <DashboardComponent />
      ) : (
        <div className="import-wrapper">
          <img src={AppLogo} className="app-logo" alt="BlockBolt" />
          <p className="import-content">
            Import your existing wallet by entering the 12-word recovery phrase
          </p>
          <textarea
            rows={3}
            className="form-control"
            placeholder="force kit end perfect concert cluster fever gap midnight chunk treat tiny"
            value={importWords}
            onChange={(e) => setImportWords(e.target.value)}
          />
          <div className="twoBottomBtns">
            <button
              className="wallet-common-button"
              onClick={() => importWallet()}
            >
              Import Wallet
            </button>
            <button
              className="wallet-common-button borderBtn"
              onClick={() => setShowImportWallet(false)}
            >
              Back
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default ImportWallet;
