import { useEffect, useState } from "react";
import axios from "axios";
import jwt from "jsonwebtoken";
import { Ed25519PublicKey } from "@mysten/sui.js/keypairs/ed25519";
import { toBigIntBE } from "bigint-buffer";
import { jwtToAddress, genAddressSeed } from "@mysten/zklogin";
import "react-toastify/dist/ReactToastify.css";
import AppLogo from "../../../../../../assets/img/logo.svg";
import addToHome from "../../../../../../assets/img/AddTohome.svg";
import suiLogo from "../../../../../../assets/img/sui-logo(white).svg";
import {
  Loader,
  client_network,
  client_network_url,
  encrypted_data,
} from "../../../../../lib/helper";
import { getJWTfromURL } from "../../../../../lib/zk_contract";
import { DashboardComponent } from "../../Dashboard/DashboardComponent";
import JoinWallet from "../JoinWallet";

// Status Functionality - default: 0, generate: 1 , scan: 2, txn: 3, completed: 4, error: 5

function IosHomeModal() {
  return (
    <>
      <div className="homeBg"></div>
      <div className="homeScreenPopup">
        <img src={addToHome} alt="BlockBolt" />
        <h4>Add To Home Screen</h4>
        <p>To install the mobile app</p>
        <p>
          tap the Share icon in your browser and choose{" "}
          <span>"Add to Home Screen"</span>
        </p>
      </div>
    </>
  );
}

function AndroidHomeModal() {
  const openPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.blockbolt.suiwallet",
      "_blank"
    );
  };
  return (
    <>
      <div className="homeAndroidBg"></div>
      <div className="androidHomeScreenPopup">
        <img src={addToHome} alt="BlockBolt" />
        <h4>Add To Home Screen</h4>
        <p>To install the mobile app</p>
        <p>
          you need to add this website to your home screen or{" "}
          <span onClick={() => openPlayStore()}>"Install Android App"</span>
        </p>
      </div>
    </>
  );
}

export default function SplashWallet() {
  const getNetwork = localStorage.getItem("network:name");
  const [showJoinWallet, setShowJoinWallet] = useState(false);
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const [loading, setLoading] = useState(false);

  // generate createPartialZKSignature
  const createPartialZKSignature = async (
    jwt: string,
    ephemeralPublicKey: Ed25519PublicKey,
    maxEpoch: number,
    jwtRandomness: bigint,
    userSalt: string
  ) => {
    return {
      jwt,
      extendedEphemeralPublicKey: toBigIntBE(
        Buffer.from(ephemeralPublicKey.toSuiBytes())
      ).toString(),
      maxEpoch,
      jwtRandomness: jwtRandomness.toString(),
      salt: userSalt,
      keyClaimName: "sub",
    };
  };

  //fetchUserSaltFromBackend
  const fetchUserSaltFromBackend = async (jwt: string) => {
    // const proxy = (url: string) => "https://cors-proxy.fringe.zone/" + url;
    try {
      // const response = await fetch("http://localhost:8081/get_prover", {

      const response = await axios.post(
        // "http://localhost:8081/get_salt",// for testing

        "https://zkloginblockbolt.onrender.com/get_salt", // live
        {
          token: jwt,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.salt;
    } catch (err) {
      console.error("Error fetching user salt:", err);
      return null;
    }
  };

  // generate processJWT
  const processJWT = async () => {
    const jwtToken = getJWTfromURL();
    if (!jwtToken) {
      // console.error("JWT is missing");
      setLoading(false);
      return;
    }
    // stored local jwt
    localStorage.setItem("wallet:jwt", jwtToken);
    if (jwtToken) {
      let userSaltStr = localStorage.getItem("wallet:salt");
      if (!userSaltStr) {
        userSaltStr = await fetchUserSaltFromBackend(jwtToken);
        if (userSaltStr) {
          localStorage.setItem("wallet:salt", userSaltStr);
          localStorage.setItem("wallet:gin", "yes");
          localStorage.setItem("network:name", encrypted_data(client_network));
          localStorage.setItem(
            "network:url",
            encrypted_data(client_network_url)
          );
        } else {
          // console.warn("Unable to retrieve salt. Exiting processing.");
          return;
        }
      }
      const userSalt = BigInt(userSaltStr);
      const derivedAddress = jwtToAddress(jwtToken, userSalt);
      if (derivedAddress) {
        localStorage.setItem("wallet:address", encrypted_data(derivedAddress));
        // setLoading(false);
        // location.reload();
        // const loginURL = "http://localhost:1234/";
        // window.location.href = loginURL; // Redirect to the login page

        // generate proofs
        const keyData = JSON.parse(localStorage.getItem("wallet:key")!);

        if (!userSaltStr) {
          return;
        }

        if (!jwtToken) {
          // console.error("JWT is missing");
          return;
        }

        const partialZk = await createPartialZKSignature(
          jwtToken,
          new Ed25519PublicKey(keyData?.ephPublic),
          keyData?.epoch,
          BigInt(keyData?.randomness),
          userSaltStr
        );

        try {
          // const proxy = (url: string) =>
          //   "https://cors-proxy.fringe.zone/" + url;
          const response = await fetch(
            // proxy("https://prover.mystenlabs.com/v1"),
            // ("https://zk.knighthood.blockbolt.io/get_prover"),
            "https://zkloginblockbolt.onrender.com/get_prover", // live
            // "http://localhost:8081/get_prover", // for testing local
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(partialZk),
            }
          ).then((res) => res.json());
          setLoading(false);
          const decoded = jwt.decode(jwtToken);
          if (typeof decoded !== "string" && decoded?.email) {
            const email = decoded.email;
            localStorage.setItem("wallet:email", encrypted_data(email));
          }
          if (typeof decoded !== "string" && decoded) {
            const claimValue = decoded.sub;
            const claimName = "sub";
            const aud = decoded.aud;
            let userSalt = localStorage.getItem("wallet:salt");

            if (
              !claimValue ||
              typeof aud !== "string" ||
              aud.trim() === "" ||
              !userSalt
            ) {
              console.error("One or more required values are missing!");
              return null;
            }

            const addrGen = genAddressSeed(
              BigInt(userSalt),
              claimName,
              claimValue,
              aud
            ).toString();
            const inputs = {
              ...response,
              addressSeed: addrGen,
            };
            localStorage.setItem("wallet:inputs", JSON.stringify(inputs));
            return addrGen;
          }
        } catch (error) {
          console.error(
            "Error during proofs fetching or address generation:",
            error
          );
        }
      }
    }
  };

  // identifyDevice
  const identifyDevice = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    if (/iphone|ipad|ipod/.test(userAgent)) {
      return "iOS";
    } else if (/android/.test(userAgent)) {
      setIsAndroidDevice(true);
      return "Android";
    } else if (/macintosh/.test(userAgent) && "ontouchend" in document) {
      return "iOS";
    } else if (/macintosh/.test(userAgent)) {
      return "Mac";
    } else if (/windows/.test(userAgent)) {
      setIsAndroidDevice(true);
      return "Windows";
    } else {
      setIsAndroidDevice(true);
      return "Unknown";
    }
  };

  useEffect(() => {
    if (getNetwork == null) {
      setLoading(true);
      processJWT();
      identifyDevice();
    }
  }, []);

  return (
    <div className="app-container">
      {loading ? (
        <Loader setLoading={setLoading} />
      ) : (
        <>
          {getNetwork !== "" &&
          getNetwork !== null &&
          getNetwork !== undefined ? (
            <DashboardComponent />
          ) : (
            <>
              {showJoinWallet ? (
                <JoinWallet />
              ) : (
                <div className="splash-wrapper">
                  <img src={AppLogo} className="app-logo" alt="BlockBolt" />
                  <p>Blockchain Payment Protocol</p>
                  <p>
                    BlockBolt is a decentralized payment protocol designed to
                    facilitate fast, secure, and cost-effective transactions on
                    the Sui Blockchain.
                  </p>
                  <button
                    className="wallet-common-button"
                    onClick={() => setShowJoinWallet(true)}
                  >
                    Let's Started
                  </button>
                  <div className="powered_by">
                    <p>Powered by</p>
                    <img src={suiLogo} className="sui-logo" alt="BlockBolt" />
                  </div>
                  {isAndroidDevice ? <AndroidHomeModal /> : <IosHomeModal />}
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
