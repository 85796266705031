import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CopyIcon from "../../../../../../assets/img/copy.svg";
import CheckIcon from "../../../../../../assets/img/check-new.svg";
import {
  Context,
  client_network,
  shorten,
  toastMsg,
} from "../../../../../lib/helper";

export function TransactionDetails(props: any) {
  const { dataTxnDigest, orderKey } = props;
  const { setShowScanner, walletNetwork, setIsTxnSuccess } =
    useContext(Context);

  const onCopyText = () => {
    navigator.clipboard.writeText(dataTxnDigest && dataTxnDigest);
    toastMsg("Copied!");
  };

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>
      <div className="common_center_parent">
        <div className="success-wrapper">
          <img src={CheckIcon} alt="BlockBolt" className="check_icon" />
          <h4>Successfully completed the transaction</h4>
          <p className="orderId">
            {orderKey !== "" && `Order Id - ${orderKey}`}
          </p>
          <p>Your Transaction Id</p>
          <div className="trans_id">
            <a
              href={
                "https://explorer.sui.io/txblock/" +
                dataTxnDigest +
                "?network=" +
                client_network
              }
              target="_blank"
            >
              {`${shorten(dataTxnDigest)}`}
            </a>
            <img
              src={CopyIcon}
              onClick={() => onCopyText()}
              className="copy_icon"
            />
          </div>
          <button
            className="wallet-common-button"
            onClick={() => {
              setIsTxnSuccess(true), setShowScanner(false);
            }}
          >
            Back To Main
          </button>
        </div>
      </div>
    </>
  );
}
