import React from "react";
import NavigationIcon from "../../../../../../assets/img/hamburger.svg";
import ScanIcon from "../../../../../../assets/img/QR-scan.svg";
import RefreshIcon from "../../../../../../assets/img/reload.svg";
import CopyIcon from "../../../../../../assets/img/copy.svg";
import suiMono from "../../../../../../assets/img/sui-mono.svg";
import sendBtn from "../../../../../../assets/img/sendBtn.svg";
import receiveBtn from "../../../../../../assets/img/receiveBtn.svg";
import { client_network, toastMsg } from "../../../../../lib/helper";
import { ManageTransactionList } from "../RecentTxnList";
import { CoinList } from "../CoinList";

const BalanceActivity = ({
  fetchAirdrop,
  walletAddress,
  balanceLoading,
  coinValue,
  keys,
  showTxn,
  txnText,
  txnList,
  coinText,
  coinList,
  fetchData,
  setShowTxn,
  setShowScanner,
  setShowTxnSend,
  setShowTxnReceive,
  setShowNavigation,
}: any) => {
  const onCopyText = () => {
    navigator.clipboard.writeText(walletAddress && walletAddress);
    toastMsg("Copied!");
  };

  return (
    <>
      <div className="dashboard-header">
        <img
          src={NavigationIcon}
          alt="BlockBolt"
          onClick={() => setShowNavigation(true)}
        />
        <div className="airdropSui" onClick={() => fetchAirdrop()}>
          <p>Faucet</p>
          <img src={suiMono} alt="suiMono" />
        </div>
      </div>
      <div className="dashboard-wrapper">
        <div className="dashboard-main">
          <div className="data-content">
            <p>Available Balance (SUI)</p>
            <img
              src={RefreshIcon}
              className="refresh-icon"
              alt="BlockBolt"
              onClick={() => {
                fetchData(walletAddress && walletAddress),
                  toastMsg("Refreshed");
              }}
            />
          </div>
          <div className="balance-content">
            <div>
              <h4>{balanceLoading ? "Loading..." : coinValue}</h4>
              <h6 className="idCopyDiv">
                {keys}
                <img
                  src={CopyIcon}
                  className="copy_icon"
                  onClick={() => onCopyText()}
                />
              </h6>
            </div>
            <img
              className="scannerIcon"
              src={ScanIcon}
              alt="BlockBolt"
              onClick={() => setShowScanner(true)}
            />
          </div>
          <div className="sendReceiveBtns">
            <button
              className="wallet-common-button"
              onClick={() => setShowTxnSend(true)}
            >
              Send
              <img className="sendBtn" src={sendBtn} alt="Send Payment" />
            </button>
            <button
              className="wallet-common-button"
              onClick={() => setShowTxnReceive(true)}
            >
              Receive
              <img className="receiveBtn" src={receiveBtn} alt="Send Payment" />
            </button>
          </div>
        </div>
        <div className="dashboardTabs">
          <h5
            className={
              showTxn ? "dashboard-activities" : "active dashboard-activities"
            }
            onClick={() => setShowTxn(false)}
          >
            Tokens
          </h5>
          <h5
            className={
              showTxn ? "active dashboard-activities" : "dashboard-activities"
            }
            onClick={() => setShowTxn(true)}
          >
            Transactions
          </h5>
        </div>
        <div className="dashboard-activities-wrapper">
          {showTxn ? (
            <>
              {txnText != "" && <h6>{txnText}</h6>}
              {txnList &&
                txnList.map((txn: any) => (
                  <ManageTransactionList
                    txn={txn}
                    walletNetwork={client_network}
                    walletAddress={walletAddress}
                  />
                ))}
            </>
          ) : (
            <>
              {coinText != "" && <h6>{coinText}</h6>}
              {coinList &&
                coinList.map((coin: any) => <CoinList coin={coin} />)}
            </>
          )}
        </div>
        <button
          className="wallet-common-button"
          onClick={() => setShowScanner(true)}
        >
          Scan QR
        </button>
      </div>
    </>
  );
};

export default BalanceActivity;
