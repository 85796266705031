import { useState, useContext, useEffect } from "react";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Context,
  TransactionLoader,
  TxnContext,
  client,
} from "../../../../lib/helper";
import CloseIcon from "../../../../../assets/img/close.svg";
import { TransactionDetails } from "../Transaction/TxnDetails";

const MintNFT = ({ identityKey }: any) => {
  const context = useContext(TxnContext);
  if (!context) {
    return;
  }
  const { setShowScanner } = useContext(Context);
  const { keyPair } = useContext(TxnContext);
  const [showLoading, setShowLoading] = useState(false);
  const [txDigest, setTxDigest] = useState("");
  const [orderId, setOrderId] = useState("");
  const [txnObject, setTxnObject] = useState<any>({});
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (identityKey) {
      const parsedData = JSON.parse(identityKey);
      setTxnObject(parsedData);
    }
  }, [identityKey]);

  const mintNFT = async () => {
    setShowLoading(true); // Temporary add
    try {
      const tx = new TransactionBlock();

      // Fetch the current minting price
      const ner = await client.getObject({
        id: txnObject.mintfee,
        options: {
          showType: true,
          showOwner: true,
          showPreviousTransaction: true,
          showDisplay: false,
          showContent: true,
          showBcs: false,
          showStorageRebate: true,
        },
      });

      const data = ner.data as any;
      const priceMint = data?.content?.fields.price;

      const coins = tx.splitCoins(tx.gas, [tx.pure(priceMint)]);
      // const attributeKeys = ['name', 'type', 'payment'];
      // const attributeValues = ['BlockBolt NFT', 'Genesis', 'Protocol'];
      // console.log(ner);

      // console.log(txnObject.mintstate);
      // console.log(txnObject.mintfee);

      // target: `${"0xf637e7c1a6c889cb21b8b088539f566a9fa0ea0030fdc401dec64bccc348753c"}::BlockBoltNFT::set_minting_state`,
      tx.moveCall({
        target: `${txnObject.packageId}::BlockBoltNFT::mint`,
        arguments: [
          tx.pure(txnObject.orderId),
          tx.object(txnObject.mintstate),
          tx.object(txnObject.mintfee),
          coins,
        ],
      });

      const response = await client.signAndExecuteTransactionBlock({
        signer: keyPair,
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });

      setTxDigest(response.digest);
      setShowSuccess(true);
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false); // Hide loading indicator regardless of success or failure
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>

      {showSuccess ? (
        <TransactionDetails dataTxnDigest={txDigest} orderKey={orderId} />
      ) : (
        Object.keys(txnObject).length > 0 && (
          <div className="common_center_parent">
            <div className="transaction-wrapper">
              {showLoading ? (
                <TransactionLoader />
              ) : (
                <>
                  {txnObject !== null && (
                    <>
                      <img
                        src={CloseIcon}
                        alt="BlockBolt"
                        className="transaction-close-btn"
                        onClick={() => setShowScanner(false)}
                      />
                      <>
                        <div className="transaction-content">
                          <p>To </p>
                          <input
                            readOnly
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Enter address"
                            value={`${
                              txnObject.name !== undefined && txnObject.name
                            }`}
                          />
                          <p>Label</p>
                          <input
                            readOnly
                            disabled
                            type="text"
                            className="form-control"
                            placeholder="Enter label"
                            value={`${
                              txnObject.description !== undefined &&
                              txnObject.description
                            }`}
                          />
                          {/* <p>Amount</p> */}
                          {/* <input
                                readOnly
                                disabled
                                type="text"
                                className="form-control"
                                placeholder="Enter amount"
                                value={identityKey.amount}
                            /> */}
                        </div>
                        <button
                          className="transaction-btn wallet-common-button"
                          onClick={() => mintNFT()}
                        >
                          Mint Now
                        </button>
                      </>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default MintNFT;
