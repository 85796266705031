// import SuiMono from "../../../../../assets/img/suiMono.png";
import suiMono from "../../../../../../assets/img/sui-mono.svg"
import { formatAmount } from "../../../../../lib/helper";

export function CoinList(props: any) {
  const { coin } = props;

  // Using optional chaining to safely access nested properties
  // new 4 dec addeddd
  const coinId = coin?.metadata?.id;

  // Conditional rendering based on the existence of coin and coin.metadata
  // if (!coin || !coin.metadata) {
  //   // Render a fallback UI, like a loading indicator or a placeholder
  //   return <div>Loading...</div>;
  // }
  return (
    // <div className="coin-list-wrapper " key={coin.metadata.id}>
    <div className="coin-list-wrapper " key={coinId}>
      <div className="coin-img-wrapper">
        {/* {coin.metadata.iconUrl !== null ? (
          <img src={`${coin.metadata.iconUrl}`} />
        ) : (
          coin.metadata.iconUrl == null && <img src={suiMono} id="suiMono" />
        )} */}
        {/* new 4 dec addeddd */}
        {coin && coin.metadata && coin.metadata.iconUrl !== null ? (
          <img src={`${coin.metadata.iconUrl}`} />
        ) : (
          <img src={suiMono} id="suiMono" />
        )}

      </div>
      <div className="coin-content-wrapper">
        {/* <h5>{coin.metadata.name}</h5>
        <p>{formatAmount(coin.balance)}</p> */}
        {coin && coin.metadata ? (
          <>
            <h5>{coin.metadata.name}</h5>
            <p>{formatAmount(coin.balance)}</p>
          </>
        ) : (
          <></>
          // Render some fallback UI here, like a loading indicator or placeholder
        )}
      </div>
    </div>
  );
}

