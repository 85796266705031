/// Helpers to interact with the Sui network
import { client } from "./helper";

export const SUI_COIN_TYPE = "0x2::sui::SUI";
export const SUI_PACKAGE_TESTNET =
  "0x2b4232ac9939d5569a8ef75f951c8448749b02a25143000d89c336238ec69558";
export const SUI_WOOCOMMERCE_TESTNET =
  "0xcb76d006828cf6f5afbc8885a62ce91f1127ce71064deb2b8b1ffab3896a2c29";
export const SUI_WOOCOMMERCE_TREASURY =
  "0xf0bb44558fc7a21a0a3772f5459a05fb574d7e5ecc85cb5eaa80f1a2e619327e";
// const SUI_PACKAGE_MAINNET = '0x20d8abb0943081c206cd6a58c1cbc3fd529184d973aa1723f923a680c1970ffb';
// const SUI_PACKAGE_DEVNET = '0x0ebba601ea064e9c65ffad57b4c4ba499bae334fef9b023b3655eeee51c418a4';

export const SUI_NFT_CONTRACT =
  "0xda4216976342ddfe2bd3254097bbd4c2fb66ec8dc764d13953cd2abd4306e8b5";

// const RPC_MAINNET = new JsonRpcProvider(new Connection({
//     fullnode: 'https://fullnode.mainnet.sui.io:443/',
//     faucet: 'https://faucet.mainnet.sui.io/gas',
// }));

// const RPC_DEVNET = new JsonRpcProvider(new Connection({
//     fullnode: 'https://fullnode.devnet.sui.io:443/',
//     faucet: 'https://faucet.devnet.sui.io/gas',
// }));

// const RPC_TESTNET = new JsonRpcProvider(new Connection({
//     fullnode: 'https://fullnode.testnet.sui.io:443/',
//     faucet: 'https://faucet.testnet.sui.io/gas',
// }));

type Config = {
  rpc: any;
  packageId: string;
};

export function getSuiConfig(network: string): Config {
  // const special = localStorage.getItem('polymedia.special') === '1';
  switch (network) {
    // case 'mainnet':
    //     return {
    //         rpc: RPC_MAINNET,
    //         packageId: SUI_PACKAGE_MAINNET,
    //     };
    // case 'devnet':
    //     return {
    //         rpc: RPC_DEVNET,
    //         packageId: SUI_PACKAGE_DEVNET,
    //     };
    case "testnet":
      return {
        rpc: client,
        packageId: SUI_PACKAGE_TESTNET,
      };
    default:
      throw new Error("Invalid network: " + network);
  }
}
