import { useState, useContext, useRef, useEffect } from "react";
import { TransactionBlock } from "@mysten/sui.js/transactions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { ZkSignatureInputs } from "@mysten/zklogin";
import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
import { getZkLoginSignature } from "@mysten/zklogin";
import axios from "axios";
import {
  Context,
  TransactionLoader,
  TxnContext,
  client,
  client_fullnode_url,
  client_network,
  decrypted_data,
  toastMsg,
} from "../../../../../lib/helper";
import {
  IDO_RPC_INVESTING,
  IDO_PACKAGE_TESTNET,
  Boltpay,
  getBoltpay,
} from "../../../../../lib/ido_contract";
import {
  SUI_COIN_TYPE,
  SUI_PACKAGE_TESTNET,
  SUI_WOOCOMMERCE_TESTNET,
  SUI_WOOCOMMERCE_TREASURY,
  getSuiConfig,
} from "../../../../../lib/sui_contract";
import CloseIcon from "../../../../../../assets/img/close.svg";

export function TransactionConfirmation(props: any) {
  const isGLogin = localStorage.getItem("wallet:gin");
  const { setTxnDigest, setShowDetails, setOrderKey } = props;
  const { setShowScanner, coinValue, walletAddress } = useContext(Context);
  const { scanResultWebCam, keyPair } = useContext(TxnContext);
  const [showLoading, setShowLoading] = useState(false);
  const [txnObject, setTxnObject] = useState<any>({});
  const refIsReloadInProgress = useRef(false);
  let { rpc, packageId } = getSuiConfig(`${client_network}`);

  const verifyTxnWithZK = async (tx: any) => {
    const savedAddress = localStorage.getItem("wallet:address");
    const state = JSON.parse(localStorage.getItem("wallet:key") as any);
    const inputs = JSON.parse(localStorage.getItem("wallet:inputs") as any);
    if (!state) {
      console.error("Required state not found in localStorage");
      return;
    }
    if (!inputs) {
      console.error("Required inputs not found in localStorage");
      return;
    }
    if (!savedAddress) {
      console.error("Required saved not found in localStorage");
      return;
    }
    tx.setSender(decrypted_data(savedAddress));

    const ephemeralKeyPair = Ed25519Keypair.fromSecretKey(
      Buffer.from(state.ephPrivate, "base64")
    );

    const preSign = await tx.sign({
      client: client,
      signer: ephemeralKeyPair,
    });

    const zkSignature = getZkLoginSignature({
      inputs: inputs,
      maxEpoch: state.epoch,
      userSignature: preSign.signature,
    });

    try {
      const result = await client.executeTransactionBlock({
        transactionBlock: preSign.bytes,
        signature: zkSignature,
      });
      if (result.digest) {
        setTxnDigest(result.digest);
        setOrderKey(txnObject.order_id);
        setShowLoading(false);
        setShowDetails(true);
      }
    } catch (error) {
      setShowLoading(false);
      toastMsg("OOPS!! The SUI network may be down");
    }
  };

  const verifyTxnWithNormal = async (tx: any) => {
    try {
      const result = client.signAndExecuteTransactionBlock({
        signer: keyPair,
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });
      result.then((response: any) => {
        if (response && response.digest) {
          if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "success"
          ) {
            const txn_digest = response && response.digest;
            if (
              txn_digest !== "" &&
              txn_digest !== null &&
              txn_digest !== undefined
            ) {
              setTxnDigest(txn_digest);
              setOrderKey(txnObject.order_id);
              setShowLoading(false);
              setShowDetails(true);
            }
          } else if (
            response &&
            response.effects &&
            response.effects.status &&
            response.effects.status.status === "failure"
          ) {
            setShowLoading(false);
            toastMsg(`Transaction Failed: ${response.effects.status.error}`);
          }
        } else {
          setShowLoading(false);
          toastMsg("Transaction Failed");
        }
      });
    } catch (error) {
      setShowLoading(false);
      toastMsg("OOPS!! The SUI network may be down");
    }
  };

  const verifyTransaction = async (tx: any) => {
    if (isGLogin == "yes") {
      verifyTxnWithZK(tx);
    } else {
      verifyTxnWithNormal(tx);
    }
  };

  const createTransaction = async () => {
    setShowLoading(true); //tmp add
    const tx = new TransactionBlock();
    const txAmount = txnObject.amount;
    const txMuldyValue = Math.floor(+txAmount * 1_000_000_000);

    if (txnObject.coin_name.toLowerCase() == "sui") {
      const coins = tx.splitCoins(tx.gas, [tx.pure(txMuldyValue)]);
      if (txnObject.woocommerce_name !== undefined) {
        tx.moveCall({
          target: `${SUI_WOOCOMMERCE_TESTNET}::woo_commerce::create_tx`,
          arguments: [
            coins,
            tx.object(`${SUI_WOOCOMMERCE_TREASURY}`),
            tx.pure(txnObject.woocommerce_name),
            tx.pure(txnObject.merchant_address),
            tx.pure(txMuldyValue),
            tx.pure(txnObject.order_id),
          ],
          typeArguments: [SUI_COIN_TYPE],
        });
        tx.setGasBudget(10000000);
        verifyTransaction(tx);
      } else {
        tx.moveCall({
          target: `${SUI_PACKAGE_TESTNET}::boltpay::create_tx`,
          arguments: [
            coins,
            tx.pure(txnObject.order_id),
            tx.pure(txnObject.merchant_name),
            tx.pure(txnObject.merchant_address),
            tx.pure(txMuldyValue),
          ],
          typeArguments: [SUI_COIN_TYPE],
        });
        verifyTransaction(tx);
      }
    } else {
      let res = await fetch(`${client_fullnode_url}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jsonrpc: "2.0",
          id: 1,
          method: "suix_getCoins",
          params: [walletAddress, txnObject.coin_type],
        }),
      });
      let json = await res.json();
      if (json && json.result && json.result.data) {
        let isNotValid = 0;
        for (var i = 0; i < json.result.data.length; i++) {
          if (json.result.data[i].balance > txMuldyValue) {
            const cointype0 = txnObject.coin_type;
            tx.moveCall({
              target: `${SUI_PACKAGE_TESTNET}::boltpay::create_tx`,
              arguments: [
                tx.object(json.result.data[i].coinObjectId),
                tx.pure(txnObject.order_id),
                tx.pure(txnObject.merchant_name),
                tx.pure(txnObject.merchant_address),
                tx.pure(txMuldyValue),
              ],
              typeArguments: [cointype0],
            });
            verifyTransaction(tx);
            return;
          } else {
            isNotValid += 1;
          }
        }
        if (isNotValid !== 0) {
          toastMsg("OOPS! You dont have sufficient balance");
        }
      }
    }
  };

  const fundWithApi = (txn_id: any) => {
    const listingData = {
      object_id: txnObject.object_id,
      wallet_address: txnObject.customer_address,
      transaction_id: txn_id,
    };
    axios.post(IDO_RPC_INVESTING, listingData).then(() => {
      setTxnDigest(txn_id);
      setShowLoading(false);
      setShowDetails(true);
    });
  };

  const verifyIdoTxnWithZK = async (tx: any) => {
    const savedAddress = localStorage.getItem("wallet:address");
    const state = JSON.parse(localStorage.getItem("wallet:key") as any);
    const inputs = JSON.parse(localStorage.getItem("wallet:inputs") as any);
    if (!state) {
      console.error("Required state not found in localStorage");
      return;
    }
    if (!inputs) {
      console.error("Required inputs not found in localStorage");
      return;
    }
    if (!savedAddress) {
      console.error("Required saved not found in localStorage");
      return;
    }
    tx.setSender(decrypted_data(savedAddress));

    const ephemeralKeyPair = Ed25519Keypair.fromSecretKey(
      Buffer.from(state.ephPrivate, "base64")
    );

    const preSign = await tx.sign({
      client: client,
      signer: ephemeralKeyPair,
    });

    const zkSignature = getZkLoginSignature({
      inputs: inputs,
      maxEpoch: state.epoch,
      userSignature: preSign.signature,
    });

    try {
      const result = await client.executeTransactionBlock({
        transactionBlock: preSign.bytes,
        signature: zkSignature,
      });
      if (result.digest) {
        fundWithApi(result.digest);
      }
    } catch (error) {
      setShowLoading(false);
      toastMsg("OOPS!! The SUI network may be down");
    }
  };

  const verifyIdoTxnWithNormal = async (tx: any) => {
    const response = await client.signAndExecuteTransactionBlock({
      signer: keyPair,
      transactionBlock: tx,
      options: {
        showEffects: true,
      },
    });
    if (response && response.effects) {
      const effects = response.effects;
      if (effects.status.status == "success") {
        const transaction_id = effects.transactionDigest;
        fundWithApi(transaction_id);
      } else {
        setShowLoading(false);
        toastMsg("Transaction Failed");
      }
    }
  };

  const verifyIdoTransaction = async (tx: any) => {
    if (isGLogin == "yes") {
      verifyIdoTxnWithZK(tx);
    } else {
      verifyIdoTxnWithNormal(tx);
    }
  };

  const handleClickWithIdo = async (boltpay: any) => {
    try {
      const tx = new TransactionBlock();
      const addAmount = Math.floor(txnObject.amount * 1_000_000_000);

      let fundingCoin: ReturnType<TransactionBlock["splitCoins"]>;
      if (boltpay.collatType === SUI_COIN_TYPE) {
        fundingCoin = tx.splitCoins(tx.gas, [tx.pure(addAmount)]);
      } else {
        const paginatedCoins = await rpc.getCoins({
          owner: txnObject.customer_address,
          coinType: boltpay.collatType,
        });
        const [firstCoin, ...otherCoins] = paginatedCoins.data;
        const firstCoinInput = tx.object(firstCoin.coinObjectId);
        if (otherCoins.length) {
          tx.mergeCoins(
            firstCoinInput,
            otherCoins.map((coin: any) => tx.object(coin.coinObjectId))
          );
        }
        fundingCoin = tx.splitCoins(firstCoinInput, [tx.pure(addAmount)]);
      }
      const txAmount = txnObject.amount;
      const txMuldyValue = Math.floor(+txAmount * 1_000_000_000);

      tx.moveCall({
        target: `${IDO_PACKAGE_TESTNET}::boltpay::invest`,
        typeArguments: [boltpay.collatType],
        arguments: [
          tx.object(txnObject.object_id),
          tx.pure("Powered by Boltpay"),
          tx.pure(txMuldyValue),
          fundingCoin,
        ],
      });

      verifyIdoTransaction(tx);
    } catch (error) {
      toastMsg("OOPS!! Something went wrong");
    }
  };

  const reloadBoltpay = async () => {
    if (refIsReloadInProgress.current) {
      return;
    }
    refIsReloadInProgress.current = true;
    await getBoltpay(txnObject.network, txnObject.object_id).then(
      (boltpay: Boltpay | null) => {
        if (!boltpay) {
          setTimeout(reloadBoltpay, 2000);
        } else {
          handleClickWithIdo(boltpay);
        }
      }
    );
    refIsReloadInProgress.current = false;
  };

  const managePayment = () => {
    if (coinValue > txnObject.amount) {
      if (txnObject.object_id !== undefined) {
        setShowLoading(true);
        reloadBoltpay();
      } else {
        setShowLoading(true);
        createTransaction();
      }
    } else {
      toastMsg("OOPS! You dont have enough balance");
    }
  };

  useEffect(() => {
    if (scanResultWebCam) {
      const parsedData = JSON.parse(scanResultWebCam);
      setTxnObject(parsedData);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="black-bg"></div>
      <div className="common_center_parent">
        <div className="transaction-wrapper">
          {showLoading ? (
            <TransactionLoader />
          ) : (
            <>
              {txnObject !== null && (
                <>
                  <img
                    src={CloseIcon}
                    alt="BlockBolt"
                    className="transaction-close-btn"
                    onClick={() => setShowScanner(false)}
                  />
                  <>
                    <div className="transaction-content">
                      <p>To </p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter address"
                        value={`${
                          txnObject.object_id !== undefined
                            ? txnObject.object_id
                            : txnObject.merchant_address
                        }`}
                      />
                      <p>Label</p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter label"
                        value={`${
                          txnObject.object_name !== undefined
                            ? txnObject.object_name
                            : txnObject.woocommerce_name !== undefined
                            ? txnObject.woocommerce_name
                            : txnObject.merchant_name
                        }`}
                      />
                      <p>Amount</p>
                      <input
                        readOnly
                        disabled
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                        value={txnObject.amount}
                      />
                    </div>
                    <button
                      className="transaction-btn wallet-common-button"
                      onClick={() => managePayment()}
                    >
                      Pay Now
                    </button>
                  </>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
