import { useEffect, useState } from "react";
import { Ed25519Keypair } from "@mysten/sui.js/keypairs/ed25519";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Context,
  NetworkContext,
  shorten,
  formatAmount,
  decrypted_data,
  SignOutConfirmation,
  Loader,
  client,
  client_faucet_url,
  toastMsg,
} from "../../../../../lib/helper";
import { QrScanner } from "../../Scanner";
import TxnSend from "../../Transaction/TxnSend";
import TxnReceive from "../../Transaction/TxnReceive";
import BalanceActivity from "../BalanceActivity";
import { NavigationNetworkProfile } from "../../SwitchNetwork";

export function DashboardComponent() {
  const navigate = useNavigate();
  const [coinValue, setCoinValue] = useState();
  const [showTxn, setShowTxn] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [showSignOut, setShowSignOut] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);
  const [isTxnSuccess, setIsTxnSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [showTxnSend, setShowTxnSend] = useState(false);
  const [showTxnReceive, setShowTxnReceive] = useState(false);
  const [keyPair, setKeyPair] = useState({});
  const [txnList, setTxnList] = useState([]);
  const [coinList, setCoinList] = useState([]);
  const [txnText, setTxnText] = useState("");
  const [coinText, setCoinText] = useState("");
  const [walletAddress, setWalletAddress] = useState("");

  // const [walletNetwork, setWalletNetwork] = useState("");
  // const [walletNetworkUrl, setWalletNetworkUrl] = useState("");

  const getMnemonic = localStorage.getItem("wallet:mnemonic");
  const isGLogin = localStorage.getItem("wallet:gin");
  const keys = shorten(walletAddress && walletAddress);

  const fetchBalance = async (publicKey: any) => {
    try {
      const coinBalanceData = await client.getBalance({
        owner: `${publicKey}`,
      });
      const balanceAmount = coinBalanceData.totalBalance;
      const formatBalance = formatAmount(balanceAmount);
      setCoinValue(formatBalance as any);
      setBalanceLoading(false);
    } catch (error) {
      console.error("Error fetching the balance:", error);
    }
  };

  const fetchTransaction = async (publicKey: any) => {
    try {
      const getTransactionResponse = await client.queryTransactionBlocks({
        filter: {
          ToAddress: `${publicKey}`,
        },
        options: {
          showEffects: true,
          showBalanceChanges: true,
          showInput: true,
        },
        limit: 100,
      });
      setTxnList([]);
      if (
        getTransactionResponse &&
        getTransactionResponse.data &&
        getTransactionResponse.data.length > 0
      ) {
        setTxnText("");
        setTxnList(getTransactionResponse.data as any);
        setLoading(false);
      } else {
        setLoading(false);
        setTxnText("You don't have any transactions");
      }
    } catch (error) {
      // Handle the error if needed
    }
  };

  const fetchCoinMetaData = async (getCoinKey: string, balance: number) => {
    try {
      const getCoinsMetaResponse = await client.getCoinMetadata({
        coinType: `${getCoinKey}`,
      });
      if (getCoinsMetaResponse && getCoinsMetaResponse.name) {
        // return response.data.result;
        return { metadata: getCoinsMetaResponse, balance, getCoinKey };
      }
      return null;
    } catch (error) {
      // Handle the error if needed
    }
  };

  const fetchCoins = async (publicKey: any) => {
    try {
      const getCoinsResponse = await client.getAllCoins({
        owner: `${publicKey}`,
      });
      if (getCoinsResponse.data && getCoinsResponse.data.length > 0) {
        setCoinText("");
        const allCoins = getCoinsResponse.data;
        if (allCoins && allCoins) {
          const coinBalancesMap = new Map<string, number>();
          allCoins.forEach((item: any) => {
            const coinType = item.coinType;
            const balance = parseFloat(item.balance);
            if (coinBalancesMap.has(coinType)) {
              coinBalancesMap.set(
                coinType,
                coinBalancesMap.get(coinType)! + balance
              );
            } else {
              coinBalancesMap.set(coinType, balance);
            }
          });
          const fetchPromises: any[] = [];
          coinBalancesMap.forEach((balance, coinType) => {
            fetchPromises.push(fetchCoinMetaData(coinType, balance));
          });
          const coinDataArray = await Promise.all(fetchPromises);
          return coinDataArray;
        }
      } else {
        setCoinText("You don't have any tokens");
      }
    } catch (error) {
      // Handle the error if needed
    }
  };

  const fetchAirdrop = async () => {
    setBalanceLoading(true);
    try {
      const result = await axios.post(
        `${client_faucet_url}`,
        {
          FixedAmountRequest: {
            recipient: `${walletAddress}`,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.data) {
        const timeout = setTimeout(() => {
          fetchData(walletAddress);
          setBalanceLoading(false);
        }, 15000);
      }
    } catch (error) {
      toastMsg("Rate Limit");
      setBalanceLoading(false);
      console.error("There was an error fetching the data:", error);
    }
  };

  const fetchTokenData = async (getPublicKey: string) => {
    const coinDataArray = await fetchCoins(getPublicKey);
    setCoinList(coinDataArray as any);
  };

  const fetchData = (walletAddress: any) => {
    setLoading(true);
    fetchBalance(walletAddress);
    fetchTokenData(walletAddress);
    fetchTransaction(walletAddress);
  };

  const getDataKeyPair = () => {
    const TEST_MNEMONICS = decrypted_data(getMnemonic!);
    const getPair = Ed25519Keypair.deriveKeypair(TEST_MNEMONICS);
    const getWalletKey = getPair && getPair.getPublicKey().toSuiAddress();
    setKeyPair(getPair);
    setWalletAddress(getWalletKey);
    fetchData(getWalletKey);
  };

  useEffect(() => {
    if (walletAddress !== "" && isTxnSuccess) {
      fetchData(walletAddress);
      setIsTxnSuccess(false);
    }
  }, [isTxnSuccess]);

  useEffect(() => {
    // const getNetwork = localStorage.getItem("network:name");
    // const getNetworkUrl = localStorage.getItem("network:url");

    // if (
    //   getNetwork !== "" &&
    //   getNetwork !== null &&
    //   getNetwork !== undefined &&
    //   getNetworkUrl !== "" &&
    //   getNetworkUrl !== null &&
    //   getNetworkUrl !== undefined
    // ) {
    //   setWalletNetwork(decrypted_data(getNetwork));
    //   setWalletNetworkUrl(decrypted_data(getNetworkUrl));
    // }

    if (window.location.hash !== "") {
      navigate("/", { replace: true });
    }

    if (isGLogin == "yes") {
      const userKey = localStorage.getItem("wallet:address");
      setWalletAddress(decrypted_data(userKey!));
      fetchData(decrypted_data(userKey!));
    }

    if (
      getMnemonic !== "" &&
      getMnemonic !== null &&
      getMnemonic !== undefined
    ) {
      getDataKeyPair();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <>
        <div className="dashboard-bottom-bg"></div>
        {loading ? (
          <Loader setLoading={setLoading} />
        ) : showScanner ? (
          <Context.Provider
            value={{
              coinValue,
              setShowScanner,
              walletAddress,
              setIsTxnSuccess,
            }}
          >
            <QrScanner keyPair={keyPair} coinList={coinList} />
          </Context.Provider>
        ) : showTxnSend ? (
          <TxnSend
            identityKey={false}
            coinValue={coinValue}
            coinList={coinList}
            setShowTxnSend={setShowTxnSend}
            keyPair={keyPair}
            setIsTxnSuccess={setIsTxnSuccess}
          />
        ) : showTxnReceive ? (
          <TxnReceive
            setShowTxnReceive={setShowTxnReceive}
            walletAddress={walletAddress}
          />
        ) : (
          <BalanceActivity
            fetchAirdrop={fetchAirdrop}
            walletAddress={walletAddress}
            balanceLoading={balanceLoading}
            coinValue={coinValue}
            keys={keys}
            showTxn={showTxn}
            txnText={txnText}
            txnList={txnList}
            coinText={coinText}
            coinList={coinList}
            fetchData={fetchData}
            setShowTxn={setShowTxn}
            setShowScanner={setShowScanner}
            setShowTxnSend={setShowTxnSend}
            setShowTxnReceive={setShowTxnReceive}
            setShowNavigation={setShowNavigation}
          />
        )}
        {showNavigation && (
          <NetworkContext.Provider
            value={{
              // walletNetwork,
              // setWalletNetwork,
              // setWalletNetworkUrl,
              setShowSignOut,
            }}
          >
            <NavigationNetworkProfile setShowNavigation={setShowNavigation} />
          </NetworkContext.Provider>
        )}
        {showSignOut && <SignOutConfirmation setShowSignOut={setShowSignOut} />}
      </>
    </>
  );
}
